.licenseplate {
  background-repeat: no-repeat;

  font-family: 'Segoe UI', Arial, sans-serif;


  font-size: 26px;
  line-height: 60px;

  font-weight: bold;
  text-align: center;

  color: #333;
  background-color: #fff;

  padding-left: 60px;
  min-height:82px;

  max-width: 100%;
  border: 5px solid red;
  border-radius: 15px;
  outline: none;

  &::placeholder {
    color: #eee;
  }
}

@media screen and (min-width: 768px) {
  .show-only-on-mobile{
    display: none !important;
  }
}

@media screen and (min-width: 650px) {
  .licenseplate {
    max-width: 560px;
    font-size: 60px;
    line-height: 100px;
  }
}

a.list-item {
  background-color: #fff;
  cursor: pointer;
}

.is-primary.input, .is-primary.textarea {
  border-color: #ffffff;
}

.checkbox input, .radio input {
  cursor: pointer;
  margin-right: 5px;
}

.checkbox, .radio {
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  position: relative;
  margin-right: 35px;
}

.radio + .radio {
  margin-left: 0em;
}


.button {
  background-color: #669fe4;
  border:1px solid #fff;
  border-radius: 0px;
  color: #FFFFFF;
}

.button:hover {
border: 1px solid #ffffff;
  color: #fff;

}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding:0px!important;
}

.button:active, .button.is-active {
  border-color: #ffffff;
  color: #ffffff;
}

.over {
  position: relative;
  display:inline-block;
  cursor: pointer;
}
.over .after {
  position: absolute;
  bottom: 5px;
  right: 0;
  max-width: 15%;
  top: 0;
  margin-top:15px;
}

.overleft {
  position: relative;
  display:inline-block;
  cursor: pointer;
}
.overleft .after {
  position: absolute;
  bottom: 5px;
  right: 10px;
  max-width: 15%;
  top: 0;
  margin-top:15px;
}

.oversmall {
  position: relative;
  display:inline-block;
  cursor: pointer;
}
.oversmall .after {
  position: absolute;
  bottom: 5px;
  right: 5px;
  max-width: 20%;
  top: 0;
  margin-top:5px;
}
