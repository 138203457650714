

.footer {
  background-color: #669fe4;
  padding: 15px 30px 15px 10px;
  span {
    color: #ffffff!important;
    font-size: 14px;
  }
}

.list-item.is-active {
  background-color: #669fe4;
  color: #fff;
}

.button.is-primary {
  background-color: #669fe4;
  border: 1px solid #fff;
}

.button.is-info {
  background-color: #669fe4;
}

.button {font-size: 14px;}
.button.is-primary:hover {background-color: #3298dc;}

.button.is-light {
  color: #669fe4;
  border: 2px solid #669fe4;
  background-color: #FFFFFF;
  font-weight: bold;
}

.button.is-light:hover {
  color: #FFFFFF!important;
  border: 2px solid #669fe4!important;
  background-color: #669fe4!important;
  font-weight: bold!important;
}


.navbar-item img {
  max-height: 3rem;
}

a.navbar-item.is-active {
  background-color: #FFFFFF;
}

.scrollup-container{
 width: 45px;
  aspect-ratio: 1/1 !important;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: solid 1px transparent;
  border-radius: 100%;
  outline: none;
  background-color: rgba(209, 209, 209, 0.8);
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg{
    width: 100%;
    height: 100%;
  }
}

.columns.is-mobile {
  display: flex;
  position: sticky;
  bottom: 0;
  padding-top: 15px;
}